import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import apple from "../images/apple_logo.png"
import play from "../images/play_logo.png"

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <h1>Stay tuned</h1>
    <h2>We are coming soon…</h2>
    <p>
      The revolution in optical sevices awaits you.
      <br />A digital optician for your needs.
    </p>
    <p>Try our free version with digital eyeglass prescription.</p>
    <div
      style={{
        display: "flex",
        alignContent: "center",
      }}
    >
      <a
        href='https://play.google.com/store/apps/details?id=com.vispecs.app'
        style={{
          float: "left",
          marginRight: "1rem",
        }}
      >
        <img src={play} style={{ height: 80, objectFit: "scale-down" }} />
      </a>
      <a
        href='https://apps.apple.com/at/app/vispecs/id1508489528'
        style={{
          float: "left",
        }}
      >
        <img
          src={apple}
          style={{ height: 80, objectFit: "scale-down" }}
        />
      </a>
    </div>
  </Layout>
)

export default IndexPage
